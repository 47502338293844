import React from "react";

const myStyle = {
  "text-align": "center",
  margin: "0",
  "margin-left": "-10px",
};

function HireMe() {
  return (
    <>
      <section className="section-sm bg-primary">
        <div className="container text-center text-sm-left">
          <div className="row align-items-center" style={myStyle}>
            <div className="col-sm offset-md-1 mb-4 mb-md-0" style={myStyle}>
              <h6 className="title text-light">Want to work with me?</h6>
              <p className="m-0 text-light">
                Always feel Free to Contact & Hire me
              </p>
              <br />
              <button
                className="btn btn-lg my-font btn-light rounded"
              >
                <a href="https://github.com/meharsh2001">Checkout github</a>
              </button>
              <button
                className="btn btn-lg my-font btn-light rounded"
              >
                <a href="mailto:meharsh2001@gmail.com">Contact via email</a>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="section-sm bg-primary skills" aria-label="Gallery">
        <div className="skills-inner">
          <div className="align-items-center row">
            <img
              src="https://w7.pngwing.com/pngs/545/451/png-transparent-node-js-express-js-javascript-solution-stack-web-application-others-angle-text-rectangle.png"
              alt="logo"
            />
            <img
              src="https://img.icons8.com/?size=256&id=t2x6DtCn5Zzx&format=png"
              alt="logo"
            />
            <img
              src="https://1000logos.net/wp-content/uploads/2020/08/MongoDB-Logo.png"
              alt="logo"
            />
            <img
              src="https://seeklogo.com/images/R/require-js-logo-2DF0DAF601-seeklogo.com.png"
              alt="logo"
            />
            <img src="assets/imgs/jquery.png" alt="logo" />
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpsCICzSa4yOAUbTa4InesB49pZitRkTi19XrQ9RntRg&s"
              alt="logo"
            />
          </div>
        </div>
      </section>
    </>
  );
}
export default HireMe;

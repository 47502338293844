import React from "react";
import $ from "jquery";

function Main() {
  var subtitleValue = 0;
  var subtitle = "FULL STACK DEVELOPER ";
  var currentValue = "FULL STACK DEVELOPER ";
  var reverse = false;
  setInterval(function () {
    if (!reverse) {
      subtitleValue = subtitleValue + 1;
      currentValue = subtitle.substring(0, subtitleValue);
      $(".header-subtitle span").html(currentValue);
      if (subtitleValue === subtitle.length) reverse = true;
    } else {
      subtitleValue = subtitleValue - 1;
      currentValue = subtitle.substring(0, subtitleValue);
      $(".header-subtitle span").html(currentValue);
      if (subtitleValue === 0) {
        reverse = false;
        subtitleValue = 0;
        if (subtitle === "MERN DEVELOPER ") subtitle = "FULL STACK DEVELOPER ";
        else subtitle = "MERN DEVELOPER ";
      }
    }
  }, 120);
  return (
    <header id="home" className="header">
      <div className="overlay"></div>
      <div className="header-content container">
        <h1 className="header-title" style={{ lineHeight: 1, width: "100vh" }}>
          <span className="up">HI!</span>
          <span className="down">
            I am <br />
            Harsh Jain
          </span>
        </h1>
        <p className="header-subtitle" style={{ width: "100vh" }}>
          <br />A <span></span>
        </p>

        <a href="#portfolio" className="btn btn-primary">
          Visit My Works
        </a>
      </div>
    </header>
  );
}
export default Main;

import React from "react";

function Skill(props) {
  return (
    <div className="skill-item offset-sm-2 offset-md-3">
      <i className={"font-awesome " + props.icon}></i>
    </div>
  );
}

function Skills() {
  return (
    <>
      <section className="section-sm bg-primary skills" aria-label="Gallery">
        <div className="skills-inner">
          <div className="align-items-center row">
            <Skill icon="fa-brands fa-html5" />
            <Skill icon="fa-brands fa-css3-alt" />
            <Skill icon="fa-brands fa-bootstrap" />
            <Skill icon="fa-brands fa-js" />
            <Skill icon="fa-brands fa-less" />
            <Skill icon="fa-brands fa-linux" />
          </div>
        </div>
      </section>
      <section className="section-sm bg-primary skills" aria-label="Gallery">
        <div className="skills-inner">
          <div className="align-items-center row">
            <Skill icon="fa-brands fa-grunt" />
            <Skill icon="fa-brands fa-node" />
            <Skill icon="fa-brands fa-react" />
            <Skill icon="fa-solid fa-database" title="mongodb" />
            <Skill icon="fa-brands fa-aws" />
            <Skill icon="fa-brands fa-wordpress" />
          </div>
        </div>
      </section>
    </>
  );
}
export default Skills;

import React from "react";

function About() {
  return (
    <section className="section pt-0" id="about">
      <div className="container text-center">
        <div className="about">
          <div className="about-img-holder">
            <img
              src="assets/imgs/harsh.png"
              className="about-img"
              alt="img alt text"
            />
          </div>
          <div className="about-caption">
            <p className="section-subtitle">Who Am I ?</p>
            <h2 className="section-title mb-3">About Me</h2>
            <p>
              I have around 3 years of experience in full stack development.
              Also i worked on 508 compliant website. I completed my graduation
              from JIMS Rohini affiliated with GGSIPU. I have worked on multiple
              projects at the same time and I can prioritize what's urgent and
              try for each project to be completed before the deadline.
              Currently, I am working remotely and looking for opportunities.
            </p>
            <a href="assets/Harsh Jain.pdf" download="HarshJainCV.pdf">
              <button className="btn-rounded btn btn-outline-primary mt-4">
                Download CV
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
export default About;

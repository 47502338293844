import React from "react";

function Footer() {
  return (
    <div className="container">
      <footer className="footer">
        <p className="mb-0">
          Copyright {new Date().getFullYear()}
          &copy; <a href="https://www.instagram.com/m_harsh_hj/">Harsh Jain</a>
        </p>
        <div className="social-links text-right m-auto ml-sm-auto">
          <a href="https://github.com/meharsh2001" className="link">
            <i className="fa fa-github" style={{top: 10}}></i>
          </a>
          <a href="https://www.instagram.com/m_harsh_hj/" className="link">
            <i className="fa fa-brands fa-instagram" style={{top: 10}}></i>
          </a>
          <a href="https://linkedin.com/in/harsh-jain-048130ba" className="link">
            <i className="fa fa-linkedin" style={{top: 10}}></i>
          </a>
          <a href="mailto:meharsh2001@gmail.com" className="link">
            <i className="fa fa-brands fa-google" style={{top: 10}}></i>
          </a>
          <a href="https://www.facebook.com/meharshhj" className="link display-none">
            <i className="fa fa-brands fa-facebook" style={{top: 10}}></i>
          </a>
        </div>
      </footer>
    </div>
  );
}
export default Footer;

import React from "react";
import ReactDOM from "react-dom";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./components/Home";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import "../node_modules/font-awesome/css/font-awesome.min.css";

var outgoingApi = "https://meharshjain-backend.netlify.app/.netlify/functions/api";//"http://localhost:5000";
const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename="/">
    <Routes>
      <Route
        path="/"
        element={
          <Provider template={AlertTemplate} {...options}>
            <Home outgoingApi={outgoingApi} />
          </Provider>
        }
      ></Route>
      <Route
        path="*"
        element={
          <Provider template={AlertTemplate} {...options}>
            <Home outgoingApi={outgoingApi} />
          </Provider>
        }
      ></Route>
    </Routes>
  </BrowserRouter>
);

function Project(props) {
  return (
    <div className="col-md-4">
      {props.href === "/maintenance" ? (
        <i class="fa-solid fa-lock"></i>
      ) : (
        <i class="fa-solid fa-computer-mouse"></i>
      )}
      <a href={props.href} className="portfolio-card" target={"_blank"}>
        <img
          src={props.src}
          className="portfolio-card-img"
          alt={props.alt}
          style={props.style}
        />
        <span className="portfolio-card-overlay">
          <span className="portfolio-card-caption">
            <h4>{props.title}</h4>
            <p className="font-weight-normal">{props.info}</p>
          </span>
        </span>
      </a>
    </div>
  );
}
export default Project;

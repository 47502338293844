import React from "react";
import $ from "jquery";
function Header(props) {
  function navToggle(e) {
    if (props.onClick) return props.onClick(e);
    $(e.currentTarget).toggleClass("is-active");
    $("ul.nav").toggleClass("show");
  }
  const imgStyle = {
    height: "8rem",
    width: "12rem"
  }
  return (
    <nav className="custom-navbar" data-spy="affix" data-offset-top="20">
    <img src="assets/imgs/ganeshay.png" style={imgStyle}/>
      <div className="container">
        <a className="logo" href="/">
          meHarshJain
        </a>
        
        <ul className="nav">
          <li className="item">
            <a className="link" href="#home">
              Home
            </a>
          </li>
          <li className="item">
            <a className="link" href="#about">
              About
            </a>
          </li>
          <li className="item">
            <a className="link" href="#portfolio">
              Projects
            </a>
          </li>
          <li className="item">
            <a className="link" href="#contact">
              Contact
            </a>
          </li>
        </ul>
        <a
          href="javascript:void(0)"
          id="nav-toggle"
          className="hamburger hamburger--elastic"
          onClick={navToggle}
        >
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </a>
      </div>
    </nav>
  );
}
export default Header;
